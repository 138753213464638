export default {

  loadingDivision: false,
  loadingMarket: false,
  loadingFacility: false,
  divisions: [ ],
  markets: [ ],
  facilities: [ ],
  selectedDivision: null,
  selectedMarket: null,
  selectedFacility: null
}
