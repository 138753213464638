<template>
  <v-container>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" xs="12" sm="8">
        <v-card>
          <v-card-title color="primary">
            <span class="display-1">Vaccine Scheduling Maintenance</span>
          </v-card-title>
          <v-card-text color="primary">
            <v-container>
              <v-row class="text-center">
                <v-col>
                  <v-img contain max-height="500" src="@/assets/maintenance.png" />
                </v-col>
              </v-row>
              <v-row class="text-center">
                <v-col>
                  <h2 class="line-height">Sorry, the Vaccine Scheduling app is currently undergoing maintenance. Please try again in a few minutes.</h2>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style>
  .line-height {
    line-height: 1.6;
  }
</style>
