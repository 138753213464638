import axios from 'axios'
import router from '../../router'

const baseApi = process.env.VUE_APP_API_PUBLIC

// Request interceptor
axios.interceptors.request.use(
  function (config) {
    config.headers['X-VaccineScheduler-Version'] = process.env.VUE_APP_BUILDVERSION
    return config
  },
  function (error) {
    // Do something with request error
    return Promise.reject(error)
  }
)

// Response interceptor
axios.interceptors.response.use(
  function (response) {
    // Do something with response data
    return response
  },
  function (error) {
    // Do something with response error
    if (error.response.status === 401 || error.response.status === 403) {
      router.push(
        '/access/'
      )
      return Promise.reject(error)
    }

    return Promise.reject(error)
  }
)

const apiService = {
  getBase: function (method) {
    return axios.get(baseApi + method)
  },

  getApi: function (method) {
    return axios.get(baseApi + method)
  },

  put: function (method, body) {
    return axios.put(baseApi + method, body)
  },

  put_absolute: function (absoluteUrl, body) {
    return axios.put(absoluteUrl, body)
  },

  postBase: function (method, body) {
    return axios.post(baseApi + method, body)
  },

  postApi: function (method, body) {
    return axios.post(baseApi + method, body)
  },

  delete: function (method) {
    return axios.delete(baseApi + method)
  },

  get_local: function (method) {
    return axios.get(method)
  }
}

export default apiService
