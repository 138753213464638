/**
 * Vuex
 *
 * @library
 *
 * https://vuex.vuejs.org/en/
 */

// Lib imports
import '@babel/polyfill'
import Vue from 'vue'
import Vuex from 'vuex'

// Store functionality
import actions from './actions'
import getters from './getters'
import modules from './modules'
import mutations from './mutations'
import state from './state'
import { vuexOidcCreateStoreModule } from 'vuex-oidc'
import axios from 'axios'

Vue.use(Vuex)

// Create a new store
const store = new Vuex.Store({
  actions,
  getters,
  modules: {
    ...modules,
    oidcStore: vuexOidcCreateStoreModule(
      {
        authority: process.env.VUE_APP_AUTHORITY,
        clientId: process.env.VUE_APP_CLIENTID,
        redirectUri: process.env.VUE_APP_REDIRECTURI,
        silentRedirectUri: process.env.VUE_APP_SILENTREDIRECTURI,
        responseType: 'code',
        scope: 'openid profile',
        loadUserInfo: false,
        automaticSilentRenew: true,
        automaticSilentSignin: false
      },
      // Optional OIDC store settings
      {
        dispatchEventsOnWindow: true
      },
      // Optional OIDC event listeners
      {
        userLoaded: (user) => {
          axios.defaults.headers.common['Authorization'] = `Bearer ${user.access_token}`
        }
      }
    )
  },
  mutations,
  state
})

export default store
