import apiService from '../../../utils/api'

export default {
  
  getManufacturers: function ({ commit, dispatch }) {
    commit('setLoadingManufacturers', true)
    apiService
      .getBase(
        `vaccineManufacturers`
      )
      .then(function (response) {
        commit('setManufacturers', response.data)
      },
        (error) => {
          dispatch('error/showErrorDialog', { errorData: error }, { root: true })
        })
      .finally(function () {
        commit('setLoadingManufacturers', false)
      })
  },
  
}
