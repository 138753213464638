<template>
  <div>
    <h3>Administration Panel:</h3>
    <v-row  class="d-flex justify-center">
      <v-col cols="12" md="10">
      <v-card>
        <v-card-title>Division, Market, and Facility:</v-card-title>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                dense
                outlined
                label="Select Division"
                :items="divisions"
                item-value="division_Code"
                item-text="division_Name"
                :rules="required"
                v-model="selected.divisionCode"
                @change="triggerDivision"
                :loading="loadingDivision"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                dense
                outlined
                label="Select Market"
                :items="markets"
                item-value="market_Code"
                item-text="market_Name"
                :rules="required"
                v-model="selected.marketCode"
                @change="triggerMarket"
                :loading="loadingMarket"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                dense
                outlined
                label="Select Facility"
                :items="facilities"
                item-value="coid"
                item-text="facility_Name"
                :rules="required"
                v-model="selected.facilityCode"
                @change="triggerFacility"
                :loading="loadingFacility"
              ></v-select>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
      </v-col>
    </v-row>
    <v-row v-if="!selected.divisionCode || !selected.marketCode || !selected.facilityCode" class="d-flex justify-center">
      <v-col cols="12" md="10">
        <v-card>
        <v-alert
          border="right"
          colored-border
          type="error"
          elevation="2"
        >
          Please select a division, market, and facility above to continue
        </v-alert>
        </v-card>
      </v-col>
    </v-row>
    <v-row class="d-flex justify-center" v-else>
      <v-col cols="12" md="10">
        <v-tabs
          fixed-tabs
          v-model="tab"
        >
          <v-tab>
            Manage Vaccine Locations
          </v-tab>
          <v-tab>
            Manage Appointment Blocks
          </v-tab>
        </v-tabs>
        <v-tabs-items v-model="tab">
          <v-tab-item>
            <v-card>
              <v-card-text>
                <adminLocation :key="renderKey" @updateLocations="incrementKey"></adminLocation>
              </v-card-text>
            </v-card>
          </v-tab-item>
          <v-tab-item>
            <v-card>
              <v-card-text>
                <appointBuilder :key="renderKey"></appointBuilder>
              </v-card-text>
            </v-card>
          </v-tab-item>
        </v-tabs-items>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from "vuex";
export default {
  computed: {
    ...mapGetters(["oidcUser", "oidcIsAuthenticated"]),
    ...mapGetters("organization", ["facilities","markets","divisions","loadingDivision", "loadingMarket", "loadingFacility"]),
  },
  components: {
    appointBuilder: () => import("./appointmentBlock/index.vue"),
    adminLocation: () => import("./vaccineLocations/index.vue"),
  },
  data() {
    return {
      renderKey: 1,
      required: [(v) => !!v || "Required Field"],
      selected: {
        divisionCode: null,
        marketCode: null,
        facilityCode: null
      },
      tab: 0
    }
  },
  methods: {
    ...mapActions("organization", ["getMarkets", "getDivisions", "getFacilities", ]),
    ...mapMutations("organization", ["setSelectedMarket", "setSelectedDivision", "setSelectedFacility"]),
    ...mapActions("vaccineLocations", ["getLocationsByFacility"]),
    triggerDivision(e) {
      this.setSelectedMarket(null);
      this.setSelectedFacility(null);
      this.getMarkets(this.selected);
      this.setSelectedDivision(e);
    },
    triggerMarket(e) {
      this.setSelectedMarket(e);
      this.setSelectedFacility(null);
      this.getFacilities(this.selected);
    },
    triggerFacility(e) {
      this.setSelectedFacility(e);
      this.getLocationsByFacility(this.selected);   
    },
    incrementKey: function () {
      this.getLocationsByFacility(this.selected);
      this.renderKey++;
    },
  },
  mounted: function () {
    this.getDivisions();
  },
};
</script>

<style lang='css' scoped></style>
