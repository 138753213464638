var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-app',[_c('v-app-bar',{attrs:{"app":"","color":"secondary","dark":"","dense":""}},[(_vm.$vuetify.breakpoint.mdAndUp)?_c('span',{staticClass:"title"},[_vm._v("HCA")]):_vm._e(),_c('span',{staticClass:"pl-1 title font-weight-thin d-none d-sm-flex"},[_vm._v("Vaccine Management")]),_c('div',{staticClass:"d-flex d-sm-none"},[_c('v-btn',{attrs:{"to":{
          name: 'home'
        },"exact":"","text":"","dark":""}},[_c('v-icon',[_vm._v("mdi-home")])],1)],1),_c('v-spacer'),_c('div',{staticClass:"d-none d-sm-flex"},[_c('v-tooltip',{staticClass:"d-none d-sm-flex",attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
        var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
            name: 'home'
          },"exact":"","text":"","dark":""}},on),[_c('v-icon',[_vm._v("mdi-home")])],1)]}}])},[_c('span',[_vm._v("Home Page")])])],1),(_vm.isAdmin)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
            name: 'vaccineadmin'
          },"exact":"","text":"","dark":""}},on),[_c('v-icon',[_vm._v("mdi-account-cog")])],1)]}}],null,false,1071445293)},[_c('span',[_vm._v("Admin Panel")])]):_vm._e(),(_vm.isAdmin || _vm.isWorker)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
          var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"to":{
            name: 'proxysignup'
          },"exact":"","text":"","dark":""}},on),[_c('v-icon',[_vm._v("mdi-account-multiple-plus")])],1)]}}],null,false,738828033)},[_c('span',[_vm._v("Proxy Scheduling")])]):_vm._e(),(_vm.oidcUser)?_c('span',{staticClass:"ma-2"},[_vm._v(" "+_vm._s(_vm.oidcUser.firstName)+" "+_vm._s(_vm.oidcUser.lastName)+" ")]):_vm._e()],1),_c('v-main',[(!_vm.oidcIsAuthenticated && _vm.$route.name !== 'Callback')?_c('v-banner',{staticStyle:{"max-height":"50px"},attrs:{"single-line":"","color":"yellow"}},[_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"max-height":"50px","padding":"0"}},[_c('v-col',{staticClass:"d-flex justify-center align-center",staticStyle:{"max-height":"50px","padding":"0"}},[_c('v-icon',{staticStyle:{"padding-right":"15px"},attrs:{"slot":"icon","color":"warning"},slot:"icon"},[_vm._v(" mdi-alert-outline ")]),_c('span',{staticStyle:{"padding-right":"15px"}},[_vm._v("You are not logged in")]),_c('v-btn',{staticStyle:{"max-width":"''"},attrs:{"outlined":"","loading":_vm.isLoading},on:{"click":_vm.reloadPage}},[_vm._v(" Login Here ")])],1)],1)],1):_vm._e(),_c('v-container',{attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('v-snackbar',{attrs:{"top":"","color":_vm.snackColor},scopedSlots:_vm._u([{key:"action",fn:function(ref){
          var attrs = ref.attrs;
return [_c('v-btn',_vm._b({attrs:{"dark":"","text":"","icon":""},on:{"click":_vm.hideQuickNotify}},'v-btn',attrs,false),[_c('v-icon',[_vm._v("mdi-close")])],1)]}}]),model:{value:(_vm.displaySnackMessage),callback:function ($$v) {_vm.displaySnackMessage=$$v},expression:"displaySnackMessage"}},[_vm._v(" "+_vm._s(_vm.snackMessage)+" ")]),_c('v-dialog',{attrs:{"width":"500","persistent":true},model:{value:(_vm.displayError),callback:function ($$v) {_vm.displayError=$$v},expression:"displayError"}},[_c('v-card',[_c('v-card-title',{attrs:{"primary-title":""}},[_vm._v("Something went wrong!")]),_c('v-card-text',[_c('v-container',[_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('p',{staticClass:"text-md-center",staticStyle:{"font-size":"16px"},domProps:{"innerHTML":_vm._s(_vm.errorMessage)}})])],1),_c('v-row',[_c('v-col',[_c('v-expansion-panels',[_c('v-expansion-panel',[_c('v-expansion-panel-header',[_vm._v(" Technical Details ")]),_c('v-expansion-panel-content',[_c('v-row',[_c('v-col',[_c('span',[_vm._v("Build Number: ")]),_c('span',[_vm._v(_vm._s(_vm.getBuildNumber()))])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("Error Message: ")]),_c('span',[_vm._v(_vm._s(_vm.errorDetails))])])],1),_c('v-row',[_c('v-col',[_c('span',[_vm._v("Technical Details: ")]),_c('span',[_vm._v(_vm._s(_vm.errorTechnicalDetails))])])],1)],1)],1)],1)],1)],1)],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":_vm.hideErrorDialog}},[_vm._v("Close")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }