<template>
  <v-row>
    <v-col xs="12">
      <router-view />
    </v-col>
  </v-row>
</template>

<script>
export default { }
</script>

<style></style>
