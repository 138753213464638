import { get } from '../../vuex'

export default {
  loadingLocationAppointments: get('loadingLocationAppointments'),
  loadingAppointmentBlocks: get('loadingAppointmentBlocks'),
  deletingLocation: get('deletingLocation'),
  loadingAppointments: get('loadingAppointments'),
  locationsLoading: get('locationsLoading'),
  locations: get('locations'),
  loadingInsertBlocks: get('loadingInsertBlocks')
}
