<template>
  <div>
    <h3>Proxy Scheduling</h3>
    <v-row class="d-flex justify-center">
      <v-col cols="12" md="10">
        <!-- search box -->
        <v-row justify="center">
          <v-col cols="12" xs="12">
            <v-card>
              <v-card-title> First, verify the correct employee </v-card-title>
              <v-card-subtitle
                >Please enter 3-4 ID, Last Name, or First Name</v-card-subtitle
              >
              <v-card-text>
                <v-alert
                  border="top"
                  colored-border
                  type="info"
                  elevation="2"
                >
                  Colleagues not entered into the Vaccine Administration System cannot be proxy scheduled and will not show up in the search results.
                </v-alert>
                <v-text-field
                  label="Search"
                  outlined
                  dense
                  hint="3 character minimum required"
                  hint-persistent
                  v-model="searchField"
                  counter
                  minlength="3"
                  maxlength="100"
                ></v-text-field>
              </v-card-text>
              <v-card-actions>
                <v-layout align-end justify-end>
                  <v-btn
                    color="success"
                    @click="search"
                    :disabled="searchField.length < 3 || loadingSearch"
                  >
                    Search
                  </v-btn>
                </v-layout>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>

        <!-- search results -->
        <v-row justify="center">
          <v-col cols="12" xs="12">
            <search-results
              :key="renderkeyResults"
              :employees="searchResultsArr"
              @updateEmployeeChoice="populateEmployeeChoice"
            >
            </search-results>
          </v-col>
        </v-row>

        <!-- dialog -->
        <v-dialog v-model="showDialog" max-width="950">
          <eligibleCard v-if="user && !loadingEligibility" :user="user" :key="eligibilityKey" @refreshAppointment="refreshAppointment" @closeProxyDialog="closeDialog"/>
        </v-dialog>

      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from "vuex";
import moment from "moment";

export default {
  components: {
    searchResults: () => import("./components/searchResult"),
    eligibleCard: () => import('@/module/vaccineScheduling/signup/components/eligibleCard'),
    invalidUser: () => import('./components/invalidUser')
  },
  data() {
    return {
      user: null,
      eligibilityKey: 1,

      required: [(v) => !!v || "Required Field"],
      searchField: "",
      searchResultsArr: [],
      renderkeyResults: 1,
      showDialog: false,
      selectedEmployee: {},
      invalidUserKey: 0,
      loadingEligibility: false
    };
  },
  methods: {
    ...mapActions("users", ["getEligiblityDetails", "getUserSearch"]),
    search() {
      this.getUserSearch(this.searchField).then((response) => {
        this.renderkeyResults++;
        this.searchResultsArr = response;
      });
    },
    populateEmployeeChoice(e) {
      if (e.length > 0) {
        this.selectedEmployee = { ...e[0] }
        this.showDialog = true
        this.checkEligibility();
      } else {
        this.selectedEmployee = {}
      }
    },
    checkEligibility: function () {
      this.loadingEligibility = true
      this.getEligiblityDetails(this.selectedEmployee.id).then((response) => {
        this.user = response.data
        this.loadingEligibility = false
      })
    },
    refreshAppointment: function () {
      this.checkEligibility()
      this.eligibilityKey++
      this.invalidUserKey++
    },
    closeDialog: function () {
      this.showDialog = false
      this.selectedEmployee = {}
      this.renderkeyResults++
      this.eligibilityKey++
      this.invalidUserKey++
    }
  },
  computed: {
    ...mapState(['oidcStore']),
    ...mapGetters('users', ['checked', 'loadingSearch']),
    ...mapGetters(['oidcUser']),
    ...mapGetters('error', ['displayError']),
  },
  watch: {
     displayError(test) {
        if(test === true) {
          this.showDialog = false
          this.renderkeyResults++
        }
      }
  },
};
</script>

<style scoped>
.errorText {
  color: red;
  padding-top: 15px;
}

.active {
  background-color: #BBBCBC;
}

.bold {
  font-weight: bold;
}
</style>
