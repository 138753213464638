import apiService from '../../../utils/api'

export default {
  getEligiblityDetails: function ({ commit, dispatch }, user) {
    commit('setCheckingEligibility', true)
    return apiService
      .getBase(
        `users/${user}/`
      )
      .then(function (response) {
        return response
      },
        (error) => {
          dispatch('error/showErrorDialog', { errorData: error }, { root: true })
        })
      .finally(function () {
        commit('setCheckingEligibility', false)
        commit('setChecked', true)
      })
  },
  getEligible: function ({ commit, dispatch }, user) {
    commit('setCheckingEligibility', true)
    return apiService
      .getBase(
        `users/${user}/eligibility`
      )
      .then(function (response) {
        return response
      },
        (error) => {
          dispatch('error/showErrorDialog', { errorData: error }, { root: true })
        })
      .finally(function () {
        commit('setCheckingEligibility', false)
        commit('setChecked', true)
      })
  },
  getUserSearch: function ({ commit, dispatch }, req) {
    commit('setLoadingSearch', true)
    return apiService
        .getBase(
            `users?q=${req}`
        )
        .then(function (response) {
            return response.data
        },
            (error) => {
                dispatch('error/showErrorDialog', { errorData: error }, { root: true })
            })
        .finally(function () {
            commit('setLoadingSearch', false)
        })
},
  insertAppointment: function ({ commit, dispatch }, { appointment, personId }) {
    return apiService
      .postBase(
        `users/${personId}/scheduledAppointments`, appointment
      )
      .then(function (response) {
        return response.data
      },
        (error) => {
          dispatch('error/showErrorDialog', { errorData: error }, { root: true })
        })
      .finally(function () {
      })
  },
  deleteAppointment: function ({ commit, dispatch }, { appointmentId, personId }) {
    commit('setReleasingAppt', true)
    return apiService
      .delete(
        `users/${personId}/scheduledAppointments/${appointmentId}`
      )
      .then(function (response) {
        return response.data
      },
        (error) => {
          dispatch('error/showErrorDialog', { errorData: error }, { root: true })
        })
      .finally(function () {
        commit('setReleasingAppt', false)
      })
  },
  
}
