import Vue from 'vue'

Vue.filter('DrPhysicianLastName', function (value) {
  if (!value) return ''
  try {
    return 'Dr. ' + value.substring(0, value.indexOf(','))
  } catch {
    return value
  }
})

Vue.filter('AvatarInitials', function (value) {
  if (!value) return ''
  try {
    return value
      .split(' ')
      .map((x) => x.charAt(0))
      .join('')
      .substr(0, 2)
      .toUpperCase()
  } catch {
    return value
  }
})

Vue.filter('shortRoomNumber', function (value) {
  if (!value) return ''
  value = value.toString()
  if (value.match(/\d+/g) === null) {
    return value
  } else {
    return value.match(/\d+/g).toString()
  }
})

Vue.filter('allcaps', function (value) {
  if (!value) return ''
  value = value.toString()
  return value.toUpperCase()
})

Vue.filter('vitalSignValue', function (value) {
  if (!value) return ''
  return value.substring(0, value.indexOf('|'))
})

Vue.filter('vitalSignTime', function (value) {
  if (!value) return ''
  return value.substring(value.indexOf('|') + 13, value.length)
})

Vue.filter('CurrencyUS', function (value) {
  if (!value) return formatter.format(0)
  return formatter.format(value)
})

Vue.filter('booleanText', function (value) {
  // if (!value) return ''
  return value === 0 ? 'No' : 'Yes'
})

Vue.filter('unitType', function (value) {
  if (value === 'NURSING MEDICAL/SURGICAL') {
    return 'MedSurg'
  } else if (value === 'ICU/CCU') {
    return 'ICU'
  } else if (value === 'NURSING-OTHER') {
    return 'IMC'
  }
})

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
})
