import { vuexDispatchCustomBrowserEvent } from 'vuex-oidc'
import apiService from '../../../utils/api'

export default {
    getLocationsByFacility: function ({ commit, dispatch }, req) {
      return apiService
        .getBase(
          `vaccineLocations?facilityCoid=${req.facilityCode}`
        )
        .then(function (response) {
          commit('setLocations', response.data)
          return response
        },
          (error) => {
            if (!error.response.data || !error.response.data.technicalMessage || !/ZipCode '\d{5}' is invalid/.test(error.response.data.technicalMessage)) {
              dispatch('error/showErrorDialog', { errorData: error }, { root: true })
            }
          })
        .finally(function () {
        })
    },
    getAvailableDays: function ({commit, dispatch}, { locationId, startDateTime, user34, maxNumOfResults }) {
      return apiService
        .getBase(
          `vaccineLocations/${locationId}/appointmentBlocks/summary?startDateTime=${startDateTime}&user34=${user34}&maxNumOfResults=${maxNumOfResults}`
        )
        .then(function (response) {
          return response
        },
          (error) => {
            dispatch('error/showErrorDialog', { errorData: error }, { root: true })
          })
        .finally(function () {
        })
    },
    getLocationsByZip: function ({ commit, dispatch }, { zipCode, numResults, pageIndex, doseType, vaccineManufacturerId, user34, milesLimit }) {
      let url = `vaccineLocations?zipCode=${zipCode}&numResults=${numResults}&pageIndex=${pageIndex}&vaccineDoseType=${doseType}&user34=${user34}`

      if (milesLimit) {
        url += `&milesLimit=${milesLimit}`
      }

      if (vaccineManufacturerId) {
        url += `&vaccineManufacturerId=${vaccineManufacturerId}`
      }

      return apiService
        .getBase(
          url
        )
        .then(function (response) {
          return response
        },
          (error) => {
            if (!error.response.data || !error.response.data.technicalMessage || !/ZipCode '\d{5}' is invalid/.test(error.response.data.technicalMessage)) {
              dispatch('error/showErrorDialog', { errorData: error }, { root: true })
            }
          })
        .finally(function () {
        })
    },
    getAppointmentsByPage: function ({ commit, dispatch }, { location, startDateTime, endDateTime, numResults, pageIndex }) {
        commit('setLoadingAppointments', true)
        return apiService
          .getBase(
            // `appointments?location=${location}&startDateTime=${startDateTime}&endDateTime=${endDateTime}&numResults=${numResults}&pageIndex=${pageIndex}`
            `vaccineLocations/${location}/appointmentBlocks/available?startDate=${startDateTime}&endDate=${endDateTime}&numResults=${numResults}&pageIndex=${pageIndex}`
          )
          .then(function (response) {
            return response
          },
            (error) => {
              dispatch('error/showErrorDialog', { errorData: error }, { root: true })
            })
          .finally(function () {
            commit('setLoadingAppointments', false)
          })
      },
    getLocationAppts: function ({ commit, dispatch }, vaccineLocationId) {
        commit('setLoadingLocationAppointments', true)
        return apiService
            .getBase(
                // `locapp/loc/${locId}`
                `vaccineLocations/${vaccineLocationId}/appointmentBlocks/*/scheduledAppointments`
            )
            .then(function (response) {
                return response.data
            },
                (error) => {
                    dispatch('error/showErrorDialog', { errorData: error }, { root: true })
                })
            .finally(function () {
                commit('setLoadingLocationAppointments', false)
            })
    },  
    insertLocation: function ({ commit, dispatch }, location) {
        return apiService
          .postBase(
            // 'locations', location
            'vaccineLocations', location
          )
          .then(function (response) {
            dispatch('snack/triggerSnack', { snackMessage: 'Location Successfully Created', snackColor: 'success' }, { root: true })
            return response
          },
            (error) => {
              dispatch('error/showErrorDialog', { errorData: error }, { root: true })
              return null
            })
          .finally(function () {
          })
      },
      updateLocation: function ({ commit, dispatch }, location) {
        return apiService
          .put(
            // 'locations', location
            `vaccineLocations/${location.id}`, location
          )
          .then(function (response) {
            dispatch('snack/triggerSnack', { snackMessage: 'Location Successfully Updated', snackColor: 'success' }, { root: true })
            return response
          },
            (error) => {
              dispatch('error/showErrorDialog', { errorData: error }, { root: true })
              return null
            })
          .finally(function () {
          })
      },
      deleteLocation: function ({ commit, dispatch }, location) {
        return apiService
          .delete(
            // 'locations', location.id
            `vaccineLocations/${location.id}`
          )
          .then(function (response) {
            dispatch('snack/triggerSnack', { snackMessage: 'Location Successfully Deleted', snackColor: 'success' }, { root: true })
            return response.data
          },
            (error) => {
              dispatch('error/showErrorDialog', { errorData: error }, { root: true })
            })
          .finally(function () {
          })
      },
      bulkInsertBlocks: function ({ commit, dispatch }, blocks) {
        commit('setLoadingInsertBlocks', true);
        return apiService
          .postBase(
            // 'availability/bulk/', blocks
            `vaccineLocations/${blocks[0].location}/appointmentBlocks`, blocks
          )
          .then(function (response) {
            dispatch('snack/triggerSnack', { snackMessage: 'Appointment Blocks Successfully Created', snackColor: 'success' }, { root: true })
            return response.data
          },
            (error) => {
              dispatch('error/showErrorDialog', { errorData: error }, { root: true })
            })
          .finally(function () {
            commit('setLoadingInsertBlocks', false);
          })
      },
      getAvailabilityBlocks: function ({ commit, dispatch }, { page, pageSize, locationId, startDateTime, endDateTime }) {
        commit('setLoadingAppointmentBlocks', true)
        return apiService
          .getBase(
            // `availability?page=${page}&pageSize=${pageSize}&locationId=${locationId}&startDate=${startDateTime}&endDate=${endDateTime}`
            `vaccineLocations/${locationId}/appointmentBlocks?pageIndex=${page}&numResults=${pageSize}&startDate=${startDateTime}&endDate=${endDateTime}`
          )
          .then(function (response) {
            return response
          },
            (error) => {
              dispatch('error/showErrorDialog', { errorData: error }, { root: true })
            })
          .finally(function () {
            commit('setLoadingAppointmentBlocks', false)
          })
      },
      updateSingle: function ({ commit, dispatch }, block) {
        return apiService
          .put(
            // 'availability', block
            `vaccineLocations/${block.location}/appointmentBlocks/${block.id}`, block
          
            ).then(function (response) {
            dispatch('snack/triggerSnack', { snackMessage: 'Appointment Block Successfully Updated', snackColor: 'success' }, { root: true })
            return response.data
          },
            (error) => {
              dispatch('error/showErrorDialog', { errorData: error }, { root: true })
            }).finally(function () {
            })
      },
      deleteSingle: function ({ commit, dispatch }, block) {
        return apiService
          .delete(
            // `availability/${block.id}`
            `vaccineLocations/${block.location}/appointmentBlocks/${block.id}`
            )
          .then(function (response) {
            dispatch('snack/triggerSnack', { snackMessage: 'Appointment Block Successfully Deleted', snackColor: 'success' }, { root: true })
            return response.data
          },
            (error) => {
              dispatch('error/showErrorDialog', { errorData: error }, { root: true })
            })
          .finally(function () { })
      }
}