import { set } from '../../vuex'

export default {
  setLoadingLocationAppointments: set('loadingLocationAppointments'),
  setLoadingAppointmentBlocks: set('loadingAppointmentBlocks'),
  setDeletingLocation: set('deletingLocation'),
  setLoadingAppointments: set('loadingAppointments'),
  setLocationsLoading: set('locationsLoading'),
  setLocations: set('locations'),
  setLoadingInsertBlocks: set('loadingInsertBlocks')
}
