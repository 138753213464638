import apiService from '../../../utils/api'

export default {
  // setSelectedMarket: function ({ commit }, market) {
  //   commit('setSelectedMarket', market)
  // },
  // setSelectedDivision: function ({ commit }, division) {
  //   commit('setSelectedDivision', division)
  // },
  // setSelectedFacility: function ({ commit }, facility) {
  //   commit('setSelectedFacility', facility)
  // },
  getDivisions: function ({ commit, dispatch }) {
    commit('setLoadingDivision', true)
    return apiService
      .getBase(
        // `org/divisions`
        `organization/divisions`
      )
      .then(function (response) {
        let sortedDivisions = response.data.sort((a, b) => (a.division_name > b.division_name) ? -1 : 1)
        commit('setDivisions', sortedDivisions)
        return response
      },
      (error) => {
        dispatch('error/showErrorDialog', { errorData: error }, { root: true })
      })
      .finally(function () {
        commit('setLoadingDivision', false)
      })
  },
  getMarkets: function ({ commit, dispatch }, req) {
    if (!req) {
      commit('setMarkets', [ ])
    } else {
      commit('setLoadingMarket', true)
      return apiService
        .getBase(
          // `org/${data}/markets`
          `organization/divisions/${req.divisionCode}/markets`
        )
        .then(function (response) {
          let sortedMarkets = response.data.sort((a, b) => (a.market_name > b.market_name) ? -1 : 1)
          commit('setMarkets', sortedMarkets)
          return response
        },
        (error) => {
          dispatch('error/showErrorDialog', { errorData: error }, { root: true })
        })
        .finally(function () {
          commit('setLoadingMarket', false)
        })
    }
  },
  getFacilities: function ({ commit, dispatch }, req) {
    commit('setLoadingFacility', true)
    return apiService
      .getBase(
        // `org/${data}/facilities`
        `organization/divisions/${req.divisionCode}/markets/${req.marketCode}/facilities`
      )
      .then(function (response) {
        let sortedList = response.data.sort((a, b) => (a.facility_Name > b.facility_Name) ? 1 : -1)
        commit('setFacilities', sortedList)
        return sortedList
      },
      (error) => {
        dispatch('error/showErrorDialog', { errorData: error }, { root: true })
      })
      .finally(function () {
        commit('setLoadingFacility', false)
      })
  }
}
