<template>
  <v-app>
    <v-app-bar app color="secondary" dark dense>
      <span class="title" v-if="$vuetify.breakpoint.mdAndUp">HCA</span>
      <span class="pl-1 title font-weight-thin d-none d-sm-flex">Vaccine Management</span>
      <div class="d-flex d-sm-none">
        <v-btn
          :to="{
            name: 'home'
          }"
          exact
          text
          dark
        >
          <v-icon>mdi-home</v-icon>
        </v-btn>
      </div>
      <v-spacer></v-spacer>

      <div class="d-none d-sm-flex">
      <v-tooltip bottom class="d-none d-sm-flex">
        <template v-slot:activator="{ on }">
          <v-btn
            :to="{
              name: 'home'
            }"
            exact
            text
            dark
            v-on="on"
          >
            <v-icon>mdi-home</v-icon>
          </v-btn>
        </template>
        <span>Home Page</span>
      </v-tooltip>
      </div>

      <v-tooltip bottom v-if="isAdmin">
        <template v-slot:activator="{ on }">
          <v-btn
            :to="{
              name: 'vaccineadmin'
            }"
            exact
            text
            dark
            v-on="on"
          >
            <v-icon>mdi-account-cog</v-icon>
          </v-btn>
        </template>
        <span>Admin Panel</span>
      </v-tooltip>

       <v-tooltip bottom v-if="isAdmin || isWorker">
        <template v-slot:activator="{ on }">
          <v-btn
            :to="{
              name: 'proxysignup'
            }"
            exact
            text
            dark
            v-on="on"
          >
            <v-icon>mdi-account-multiple-plus</v-icon>
          </v-btn>
        </template>
        <span>Proxy Scheduling</span>
      </v-tooltip>

      <span v-if="oidcUser" class="ma-2">
        {{ oidcUser.firstName }} {{ oidcUser.lastName }}
      </span>
    </v-app-bar>

    <v-main>
      <v-banner
        single-line
        color="yellow"
        style="max-height: 50px;"
        v-if="!oidcIsAuthenticated && $route.name !== 'Callback'"
      >
      <v-row class="d-flex justify-center" style="max-height: 50px; padding: 0;">
        <v-col class="d-flex justify-center align-center" style="max-height: 50px; padding: 0;">
        <v-icon
          slot="icon"
          color="warning"
          style="padding-right: 15px;"
        >
          mdi-alert-outline
        </v-icon>
        <span style="padding-right: 15px;">You are not logged in</span>
          <v-btn
          style="max-width: '';"
          outlined
          :loading="isLoading"
          @click="reloadPage"
          >
            Login Here
          </v-btn>
          </v-col>
        </v-row>
      </v-banner>
      <v-container fluid>
        <router-view />
      </v-container>
    </v-main>
    <v-snackbar v-model="displaySnackMessage" top :color="snackColor">
      {{ snackMessage }}
      <template v-slot:action="{ attrs }">
        <v-btn dark text v-bind="attrs" @click="hideQuickNotify" icon><v-icon>mdi-close</v-icon></v-btn>
      </template>
    </v-snackbar>
    <v-dialog v-model="displayError" width="500" :persistent="true">
        <v-card>
          <v-card-title primary-title>Something went wrong!</v-card-title>
          <v-card-text>
            <v-container>
              <v-row>
                <v-col cols="12">
                  <p class="text-md-center" style="font-size: 16px" v-html="errorMessage"></p>
                </v-col>
              </v-row>
              <v-row>
                <v-col>
                  <v-expansion-panels>
                    <v-expansion-panel>
                      <v-expansion-panel-header>
                        Technical Details
                      </v-expansion-panel-header>
                      <v-expansion-panel-content>
                        <v-row>
                          <v-col>
                            <span>Build Number: </span>
                            <span>{{ getBuildNumber() }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <span>Error Message: </span>
                            <span>{{ errorDetails }}</span>
                          </v-col>
                        </v-row>
                        <v-row>
                          <v-col>
                            <span>Technical Details: </span>
                            <span>{{ errorTechnicalDetails }}</span>
                          </v-col>
                        </v-row>
                      </v-expansion-panel-content>
                    </v-expansion-panel>
                  </v-expansion-panels>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider></v-divider>

          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" @click="hideErrorDialog" text>Close</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
  </v-app>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
export default {
  name: 'App',
  computed: {
    ...mapGetters(['oidcUser', 'oidcIsAuthenticated']),
    ...mapGetters('error', ['errorMessage', 'errorDetails', 'errorTechnicalDetails', 'displayError', 'persistErrorDialog', 'retryMethod']),
    ...mapGetters('snack', ['displaySnack', 'snackColor', 'snackMessage']),
    isAdmin: {
      get: function () {
        if (this.oidcUser && this.oidcUser.groups) {
          if (Array.isArray(this.oidcUser.groups)) {
            return this.oidcUser.groups.find(x => {
              return x === 'ENT_C19V_ADMIN'
            }) !== null
          } else if (this.oidcUser.groups === 'ENT_C19V_ADMIN') {
            return true
          }
        }
        return false
      }
    },
     isWorker: {
      get: function () {
        if (this.oidcUser && this.oidcUser.groups) {
          if (Array.isArray(this.oidcUser.groups)) {
            return this.oidcUser.groups.find(x => {
              return x === 'ENT_C19V_WKR'
            }) !== null
          } else if (this.oidcUser.groups === 'ENT_C19V_WKR') {
            return true
          }
        }
        return false
      }
    },
    displaySnackMessage: {
      get () { return this.displaySnack },
      set (value) { this.updateDisplaySnack(value) }
    }
  },
  data: () => ({
    isLoading: false
  }),
  methods: {
    ...mapActions('error', ['hideErrorDialog', 'showErrorDialog']),
    ...mapActions('snack', ['triggerSnack', 'hideSnack', 'updateDisplaySnack']),
    reloadPage: function () {
      this.isLoading = true
      setTimeout(() => {
        this.isLoading = false
      }, 10000)
      location.reload()
    },
    hideQuickNotify: function () {
      this.hideSnack()
    },
    getBuildNumber: function () {
      return process.env.VUE_APP_BUILDVERSION
    }
  },
  mounted: function () {
  }
}
</script>
<style lang="css">
.is-link {
  cursor: pointer;
}
.application {
  font-family: "Mark for HCA", "Roboto", "Arial", sans-serif;
}


/* Temporary fix for vuetify v2 vard title and text word wrap and break */
.v-card__text, .v-card__title, .v-alert__content {
  word-break: normal !important;
}

</style>
