<template>
  <v-container >
    <v-row class="d-flex justify-center">
        <v-img class="text-center image" style="max-width: 500px;"  src="@/assets/404.png"></v-img>
    </v-row>
    <v-row justify="center">
      <v-col>
        <h2 class="text-center">The page you're looking for cannot be found</h2>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col>
        <p class="text-center"><a href="/">Click here to Go Home</a></p>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: 'Not Found',
  data: () => ({
  }),
  methods: {
  }
}
</script>

<style scoped>
  .large-text {
    font-size: 148px;
  }

  .image {
    filter: invert(20%) sepia(60%) saturate(4712%) hue-rotate(344deg) brightness(84%) contrast(92%);
  }
</style>
