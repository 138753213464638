<template>
  <v-container>
    <v-row class="d-flex justify-center align-center">
      <v-col cols="12" xs="12" sm="8">
        <v-card>
          <v-card-title color="primary">
            <span class="display-1">Access Denied</span>
          </v-card-title>
          <v-card-text color="primary">
            <v-container>
              <v-row>
                <v-col>
                  <h2 class="line-height">Your 3-4 User ID has not been provisioned with access to this page.</h2>
                  <br/>
                  <h2 class="line-height">If you feel you are receiving this message in error, please contact the Support Desk at <a href="tel:18002658422">1-800-265-8422</a> for further assistance.</h2>
                </v-col>
              </v-row>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
      </v-row>
    </v-container>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
  },
  computed: {
  }
}
</script>

<style>
  .line-height {
    line-height: 1.6;
  }
</style>
