import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import './utils/filter'
import VueTheMask from 'vue-the-mask'
import VueAppInsights from 'vue-application-insights'

Vue.config.productionTip = false
Vue.use(VueTheMask)

Vue.use(VueAppInsights, {
  baseName: 'Vaccine Scheduling UI',
  id: process.env.VUE_APP_APPINSIGHTSKEY,
  router
})

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
