import apiService from '../../../utils/api'

export default {
    getMaintenanceMode: function ({ commit, dispatch }) {
        return apiService
            .getBase(
                `health/maintenanceMode`
            )
            .then(function (response) {
              commit('setMaintenanceMode', response.data)
              return response.data
            },
            (error) => {
              commit('setMaintenanceMode', true)
            })
            .finally(function () {
            })
    }
}