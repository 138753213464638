import { set } from '../../vuex'

export default {

  setLoadingDivision: set('loadingDivision'),
  setLoadingMarket: set('loadingMarket'),
  setLoadingFacility: set('loadingFacility'),
  setMarkets: set('markets'),
  setDivisions: set('divisions'),
  setFacilities: set('facilities'),
  setSelectedMarket: set('selectedMarket'),
  setSelectedDivision: set('selectedDivision'),
  setSelectedFacility: set('selectedFacility')
}
