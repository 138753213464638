import apiService from '../../../utils/api'

export default {
    getMultiBlockAppts: function ({ commit, dispatch }, block) {
        commit('setLoadingBlockAppointments', true)
        return apiService
            .getBase(
                // `blockapp/blocks/${blockId}`
                `vaccineLocations/${block.location}/appointmentBlocks/${block.blocks}/scheduledAppointments`
            )
            .then(function (response) {
                return response.data
            },
                (error) => {
                    dispatch('error/showErrorDialog', { errorData: error }, { root: true })
                })
            .finally(function () {
                commit('setLoadingBlockAppointments', false)
            })
    },

    //go back and changne single deletes
    deleteMultiBlocks: function ({ commit, dispatch }, block) {
        commit('setDeletingBlocks', true)
        return apiService
            .delete(
                // `availabilities/${blockId}`
                `vaccineLocations/${block.location}/appointmentBlocks/${block.blocks}`
            )
            .then(function (response) {
                return response.data
            },
                (error) => {
                    dispatch('error/showErrorDialog', { errorData: error }, { root: true })
                })
            .finally(function () {
                commit('setDeletingBlocks', false)
            })
    }
}