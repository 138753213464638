import { get } from '../../vuex'

export default {

  loadingDivision: get('loadingDivision'),
  loadingMarket: get('loadingMarket'),
  loadingFacility: get('loadingFacility'),
  facilities: get('facilities'),
  markets: get('markets'),
  divisions: get('divisions'),
  selectedDivision: get('selectedDivision'),
  selectedMarket: get('selectedMarket'),
  selectedFacility: get('selectedFacility')
}
