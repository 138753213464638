<template lang="html">
  <div>
    <v-row justify="center" v-if="!this.checked && !this.loadingEligibility">
      <v-col cols="12" xs="12" md="10">
        <v-card>
          <v-row>
            <v-col cols="12" sm="12" class="text-center">
              <v-progress-circular
                :size="100"
                color="primary"
                indeterminate
              ></v-progress-circular>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-center">
              <h2>Please wait while we check your eligibility...</h2>
            </v-col>
          </v-row>
        </v-card>
      </v-col>
    </v-row>
    <v-row justify="center">
      <v-col cols="12" xs="12" md="7">
        <eligibleCard v-if="user" :user="user" :key="eligibilityKey" @refreshAppointment="refreshAppointment"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex'

export default {
  components: {
    eligibleCard: () => import('@/module/vaccineScheduling/signup/components/eligibleCard')
  },
  data () {
    return {
      user: null,
      eligibilityKey: 1,
      loadingEligibility: false
    }
  },
  computed: {
    ...mapState(['oidcStore']),
    ...mapGetters('users', ['checked']),
    ...mapGetters(['oidcUser'])
  },
  methods: {
    ...mapActions('users', ['getEligiblityDetails']),
    checkEligibility: function () {
      this.loadingEligibility = true
      this.getEligiblityDetails('self').then((response) => {
        this.user = response.data
        this.loadingEligibility = false
        this.eligibilityKey++
      })
    },
    refreshAppointment: function () {
      this.checkEligibility()
    }
  },
  mounted: function () {
    if (this.oidcUser && this.oidcUser.sub) {
      this.checkEligibility()
    }
  }
}
</script>

<style scoped>
.errorText {
  color: red;
  padding-top: 15px;
}

.active {
  background-color: #BBBCBC;
}

.bold {
  font-weight: bold;
}
</style>
