import moment from 'moment'
import Vue from 'vue'

Vue.filter('formatDateTime24', function (value) {
  if (moment(value, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
    return moment(String(value), 'YYYY-MM-DDTHH:mm:ss').format('MM/DD HH:mm')
  }
  return ''
})

Vue.filter('formatDateTimeLong24', function (value) {
  if (moment(value, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
    return moment(String(value), 'YYYY-MM-DDTHH:mm:ss').format(
      'MM/DD/YYYY HH:mm'
    )
  }
  return ''
})

Vue.filter('shortDateTime', function (value) {
  if (moment(value, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
    return moment(String(value), 'YYYY-MM-DDTHH:mm:ss').format(
      'MM-DD-YY hh:mm A'
    )
  }
  return ''
})

Vue.filter('dateDialog', function (value) {
  if (moment(value, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
    return moment(String(value), 'YYYY-MM-DDTHH:mm:ss').format('MM/DD/YY')
  }
  return ''
})

Vue.filter('shortDate', function (value) {
  if (moment(value, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
    return moment(String(value), 'YYYY-MM-DDTHH:mm:ss').format('YY-MM-DD')
  }
  return ''
})

Vue.filter('shortTime', function (value) {
  if (moment(value, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
    return moment(String(value), 'YYYY-MM-DDTHH:mm:ss').format('LT')
  }
  return ''
})

Vue.filter('shortDateTimeLocal', function (value) {
  if (moment(value, 'YYYY-MM-DDTHH:mm:ss').isValid()) {
    return moment
      .utc(String(value), 'YYYY-MM-DDTHH:mm:ss')
      .local()
      .format('MM-DD-YY hh:mm A')
  }
  return ''
})
