import Vue from 'vue'
import VueRouter from 'vue-router'
import Access from '../views/Access.vue'
import Maintenance from '../views/MaintenanceMode'
import vaccine from '../module/vaccineScheduling/index.vue'
import vaccineAdmin from '../module/vaccineScheduling/admin/index.vue'
import vaccineSignup from '../module/vaccineScheduling/signup/index.vue'
import notFound from '../views/NotFound.vue'
import proxySignup from '../module/proxySignup/index.vue'
import callback from '../views/Callback'
import store from '../store/index'
import { vuexOidcCreateRouterMiddleware, vuexOidcProcessSilentSignInCallback } from 'vuex-oidc'

Vue.use(VueRouter)

const routes = [
  {
    path: '/access',
    name: 'access',
    component: Access,
    props: true,
    meta: {
      isPublic: false
    }
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: Maintenance,
    props: true,
    meta: {
      isPublic: true
    }
  },
  {
    path: '/',
    title: 'Home Page - Example App',
    name: 'home',
    component: vaccineSignup,
    meta: {
      isPublic: false
    },
    beforeEnter: (to, from, next) => {
      checkMaitenanceMode(to, from, next)
    }
  },
  {
    path: '/managersignup',
    name: 'proxysignup',
    component: proxySignup,
    meta: {
      isPublic: false
    },
    beforeEnter: (to, from, next) => {
      checkMaitenanceMode(to, from, next).then(() => {
        // Get the user
        let user = store.getters.oidcUser
        // Check that the user is defined and has groups
        if (user && user.groups) {
          // Hold our state
          let isAdmin = false
          // Since Ping can return either a string or an array we need to check
          if (Array.isArray(user.groups)) {
            // If it's an array see if the user is in the proper group
            isAdmin = user.groups.find(x => {
              return (x === 'ENT_C19V_ADMIN' || x === 'ENT_C19V_WKR')
            }) !== null
            // Else it's a string, see if that group matches our group
          } else if (user.groups === 'ENT_C19V_ADMIN' || user.groups === 'ENT_C19V_WKR') {
            isAdmin = true
          }

          // Now do the access check
          if (!isAdmin) {
            next('/access/')
          } else {
            next()
          }
        } else {
          next('/access/')
        }
      })
    }
  },
  {
    path: '/vaccine',
    name: 'vaccine',
    component: vaccine,
    children: [
      {
        path: 'admin',
        name: 'vaccineadmin',
        component: vaccineAdmin,
        meta: {
          isPublic: false
        }
      },
      {
        path: 'signup',
        name: 'vaccinesignup',
        component: vaccineSignup,
        meta: {
          isPublic: false
        }
      }
    ],
    beforeEnter: (to, from, next) => {
      checkMaitenanceMode(to, from, next).then(() => {
        // Get the user
        let user = store.getters.oidcUser
        // Check that the user is defined and has groups
        if (user && user.groups) {
          // Hold our state
          let isAdmin = false
          // Since Ping can return either a string or an array we need to check
          if (Array.isArray(user.groups)) {
            // If it's an array see if the user is in the proper group
            isAdmin = user.groups.find(x => {
              return x === 'ENT_C19V_ADMIN'
            }) !== null
            // Else it's a string, see if that group matches our group
          } else if (user.groups === 'ENT_C19V_ADMIN') {
            isAdmin = true
          }

          // Now do the access check
          if (!isAdmin) {
            next('/access/')
          } else {
            next()
          }
        } else {
          next('/access/')
        }
      })
    }
  },
  {
    path: '/callback',
    component: callback
  },
  {
    path: '/callback-silent',
    beforeEnter: (to, from, next) => {
      vuexOidcProcessSilentSignInCallback({
        authority: process.env.VUE_APP_AUTHORITY,
        clientId: process.env.VUE_APP_CLIENTID,
        redirectUri: process.env.VUE_APP_REDIRECTURI,
        silentRedirectUri: process.env.VUE_APP_SILENTREDIRECTURI,
        responseType: 'code',
        scope: 'openid profile',
        loadUserInfo: false,
        automaticSilentRenew: true,
        automaticSilentSignin: false
      })
      next('/')
    }
  },
  {
    path: '*',
    name: 'notfound',
    component: notFound,
    meta: {
      isPublic: false
    }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

let checkMaitenanceMode = function (to, from, next) {
  return store.dispatch('maintenance/getMaintenanceMode').then((response) => {
    if (store.state.maintenance.maintenanceMode) {
      next('/maintenance')
    } else {
      next()
    }
  })
}

router.beforeEach(vuexOidcCreateRouterMiddleware(store))

export default router
