export default {
  triggerSnack: function ({ commit }, { snackMessage, snackColor }) {
    if (snackColor) {
      commit('setSnackColor', snackColor)
    } else {
      commit('setSnackColor', 'success')
    }

    commit('setSnackMessage', snackMessage)
    commit('setDisplaySnack', true)
  },
  hideSnack: function ({ commit }) {
    commit('setDisplaySnack', false)
  },
  updateDisplaySnack: function ({ commit }, { value }) {
    commit('setDisplaySnack', value)
  }
}
