export default {
  hideErrorDialog: function ({ commit }) {
    commit('setDisplayError', false)
  },
  showErrorDialog: function ({ commit }, { errorData }) {
    commit('setDisplayError', true)
    if (errorData.response.status === 403) {
      commit('setErrorMessage', 'You do not have access to this page')
      commit('setErrorDetails', JSON.stringify(errorData.response.data))
    } else {
      commit('setErrorMessage', errorData.response.data ? errorData.response.data.displayMessage : 'No error details are available')
      commit('setErrorTechnicalDetails', errorData.response.data ? errorData.response.data.technicalMessage : 'No technical error details are available')
      commit('setErrorDetails', JSON.stringify(errorData.response.data))
    }
  }
}
