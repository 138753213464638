import Vue from 'vue'
import Vuetify from 'vuetify/lib'

Vue.use(Vuetify)

export default new Vuetify({
  theme: {
    options: {
      customProperties: true
    },
    themes: {
      light: {
        primary: '#00558C',
        secondary: '#03173E',
        accent: '#E05929',
        error: '#CE2130',
        info: '#63B1BA',
        success: '#599662',
        warning: '#956C24',

        alert: '#ff0000',
        ok: '#26a65b',
        danger: '#cccc00',
        identify: '#03173E',

        astrazenecaChip: '#8E24AA',
        astrazenecaChipText: '#fff',

        modernaChip: '#B71C1C',
        modernaChipText: '#fff',

        johnsonChip: '#FFCDD2',
        johnsonChipText: '#000',

        novavaxChip: '#BBDEFB',
        novavaxChipText: '#000',

        pfizerChip: '#039BE5',
        pfizerChipText: '#fff',

        glaxosmithklineChip: '#F57C00',
        glaxosmithklineChipText: '#fff',

        firstDoseOnly: '#D35400',
        firstDoseOnlyChipText: '#fff',

        secondDoseOnly: '#3498DB',
        secondDoseOnlyChipText: '#fff',

        firstAndSecondDose: '#16A085',
        firstAndSecondDoseChipText: '#fff',

        allDoses: '#757575',
        allDosesChipText: '#fff'

      }
    }
  },
  icons: {
    iconfont: 'mdi'
  }
})
